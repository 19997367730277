<template>
  <n-layout :has-sider="!!user" class="h-screen">
    <div class="hidden md:block">
      <n-layout-sider
        v-if="!!user"
        class="h-screen bg-gray-50"
        bordered
        collapse-mode="width"
        :collapsed-width="64"
        :width="240"
        :collapsed="collapsed"
        show-trigger
        @collapse="collapsed = true"
        @expand="collapsed = false"
      >
        <div
          class="w-full py-3 text-center border-b border-b-gray-200 text-[16px] overflow-hidden whitespace-nowrap"
        >
          <div class="flex justify-center items-center gap-1">
            <img
              alt="Robotkong Booking System Logo"
              class="h-9 w-9"
              src="../assets/logos/robotkonglogo.svg"
            />
            <span v-if="!collapsed"
              ><span class="font-semibold text-lg"
                >Robot<span class="text-[#CF3C1B]">Kong</span></span
              ></span
            >
          </div>
        </div>
        <n-menu
          class="mt-6"
          :collapsed="collapsed"
          :collapsed-width="64"
          :collapsed-icon-size="22"
          :options="optionsComputed"
          @update:value="notification.destroyAll()"
        />
      </n-layout-sider>
    </div>
    <n-layout>
      <div
        class="w-full h-[40px] flex items-center justify-center bg-indigo-500 text-white text-sm font-semibold"
        v-if="isImpersonating"
      >
        You are acting on behalf of&nbsp;<strong>
          {{ impersonatedName }}</strong
        >
      </div>
      <header v-if="user" class="md:hidden">
        <nav
          class="flex gap-6 py-4 px-6 bg-gray-50 text-sm justify-between items-center"
        >
          <div class="flex justify-center items-center gap-1">
            <img
              alt="Robotkong Booking System Logo"
              class="h-9 w-9"
              src="../assets/logos/robotkonglogo.svg"
            />
            <span class="font-semibold text-lg"
              >Robot<span class="text-[#CF3C1B]">Kong</span></span
            >
          </div>
          <div class="flex gap-6 items-center">
            <a
              v-if="user"
              class="underline underline-offset-4"
              href="#"
              @click="signOut"
              >{{ isImpersonating ? 'Stop impersonation' : 'Logout' }}</a
            >
            <div @mouseenter="handleMenuTrigger">
              <n-dropdown
                :options="optionsComputed"
                @select="notification.destroyAll()"
              >
                <Bars3Icon
                  class="h-6 text-gray-700 hover:text-black cursor-pointer border border-gray-700 hover:border-black rounded p-0.5"
                />
              </n-dropdown>
            </div>
          </div>
        </nav>
      </header>
      <div class="pt-8">
        <n-message-provider placement="top-right">
          <slot />
        </n-message-provider>
      </div>
    </n-layout>
  </n-layout>
  <div v-if="false">
    <header v-if="user" class="">
      <nav class="flex gap-6 py-4 px-6 bg-indigo-50 text-sm justify-end">
        <a
          v-if="user"
          class="underline underline-offset-4"
          href="#"
          @click="signOut"
          >{{ isImpersonating ? 'Stop impersonation' : 'Logout' }}</a
        >
        <div @mouseenter="handleMenuTrigger">
          <n-dropdown
            :options="optionsComputed"
            @select="notification.destroyAll()"
          >
            <Bars3Icon
              class="h-6 text-gray-700 hover:text-black cursor-pointer border border-gray-700 hover:border-black rounded p-0.5"
            />
          </n-dropdown>
        </div>
      </nav>
    </header>
    <div class="flex w-screen h-screen">
      <div class="min-w-[256px] bg-gray-50">ok</div>
      <div class="w-full">
        <n-message-provider placement="top-right">
          <slot />
        </n-message-provider>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  Bars3Icon,
  CalendarDaysIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  ListBulletIcon,
  PlusIcon,
  PowerIcon,
  QueueListIcon,
  TableCellsIcon,
  CodeBracketSquareIcon,
  UserGroupIcon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/outline'
import { NIcon } from 'naive-ui'
import { NuxtLink } from '#components'
import { usePermissionsStoreNew } from '~/stores/usePermissionsStoreNew'
import { PermissionGuardName } from '~/types/PermissionGuardNameEnum'
import { useAuthCompanyStore } from '~/stores/useAuthCompanyStore'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

useHead({
  title: 'Robotkong booking system',
  // meta: [
  //   {name: 'description', content: "Robotkong | A booking system that's easy"}
  // ],
})

const router = useRouter()

const permissionsStore = usePermissionsStoreNew()
const user = useSupabaseUser()
const client = useSupabaseClient()
const impersonatedName = ref('')
const { data: impersonatedData } = await client
  .from('users')
  .select('impersonated_company_id')
  .eq('id', user.value?.id ?? '')
  .single()
if (impersonatedData?.impersonated_company_id) {
  const { data: companyData } = await client
    .from('companies')
    .select('name')
    .eq('id', impersonatedData?.impersonated_company_id || 0)
    .single()
  impersonatedName.value = companyData?.name ?? ''
}
const isImpersonating = computed(() => {
  return !!impersonatedData?.impersonated_company_id
})
const { auth } = useSupabaseClient()
const breakpoints = useBreakpoints(breakpointsTailwind)
const mdOrSmaller = breakpoints.smallerOrEqual('md')
const notification = useNotification()

onMounted(async () => {
  const authCompanyStore = useAuthCompanyStore()
  if (!authCompanyStore.company.id) {
    await authCompanyStore.fetchCompany()
  }
  if (!user.value) {
    const permissionsStore = usePermissionsStoreNew()
    nextTick().then(async () => {
      await permissionsStore.updatePermissions()
    })
  }
})

const collapsed = ref(false)
const handleMenuTrigger = async () => {
  await permissionsStore.updatePermissions()
}

const signOut = async () => {
  if (isImpersonating.value) {
    await navigateTo('/impersonate/end')
  } else {
    await auth.signOut()
  }
}

const optionsComputed = computed(() => {
  let ops = []
  if (
    permissionsStore.hasPermission(
      PermissionGuardName.ORGANISATION_ALL_ACCESS,
    ) &&
    !isImpersonating.value
  ) {
    ops.push({
      label: () => h(NuxtLink, { to: '/organisation/companies' }, 'Companies'),
      key: 'organisation-companies',
      icon: () =>
        h(NIcon, null, { default: () => h(ListBulletIcon, { class: 'h-5' }) }),
    })
  }
  if (
    permissionsStore.hasPermission(
      PermissionGuardName.ORGANISATION_ALL_ACCESS,
    ) &&
    !isImpersonating.value
  ) {
    ops.push({
      label: () => h(NuxtLink, { to: '/organisation/bookings' }, 'Bookings'),
      key: 'organisation-bookings',
      icon: () =>
        h(NIcon, null, { default: () => h(TableCellsIcon, { class: 'h-5' }) }),
    })
  }
  // if (permissionsStore.hasPermission('COMPANY_ALL_ACCESS')) {
  //   ops.push({
  //     label: () =>
  //       h(NuxtLink, { to: '/bookings-advanced' }, 'Bookings Advanced'),
  //     key: 'advanced',
  //     icon: () =>
  //       h(NIcon, null, { default: () => h(ListBulletIcon, { class: 'h-5' }) }),
  //   })
  // }
  // if (
  //   permissionsStore.hasPermission(
  //     PermissionGuardName.MANAGE_BOOKINGS_ACCESS,
  //   ) ||
  //   permissionsStore.hasPermission(PermissionGuardName.TRUSTED_PARNER_ACCESS) ||
  //   isImpersonating.value
  // ) {
  //   ops.push({
  //     label: () => h(NuxtLink, { to: '/bookings-advanced' }, 'Bookings'),
  //     key: 'bookings',
  //     icon: () =>
  //       h(NIcon, null, { default: () => h(TableCellsIcon, { class: 'h-5' }) }),
  //   })
  // }

  // ops.push({
  //   label: () => h(NuxtLink, { to: '/check-in' }, 'Check-ins'),
  //   key: 'checkin',
  //   icon: () =>
  //     h(NIcon, null, {
  //       default: () => h(ClipboardIcon, { class: 'h-5' }),
  //     }),
  // })
  // ops.push({
  //   label: () =>
  //     h(NuxtLink, { to: '/report-tour-numbers' }, 'Report tour numbers'),
  //   key: 'numbers',
  //   icon: () =>
  //     h(NIcon, null, {
  //       default: () => h(ClipboardIcon, { class: 'h-5' }),
  //     }),
  // })

  let children = []
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    permissionsStore.hasPermission(PermissionGuardName.CHECK_IN_ACCESS)
  ) {
    children.push({
      label: () => h(NuxtLink, { to: '/check-in' }, 'Check-in'),
      key: 'checkin',
      icon: () =>
        h(NIcon, null, {
          default: () => h(ClipboardIcon, { class: 'h-5' }),
        }),
    })
  }
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    permissionsStore.hasPermission(PermissionGuardName.TOUR_ATTENDANCE_ACCESS)
  ) {
    children.push({
      label: () => h(NuxtLink, { to: '/report-tour-numbers' }, 'Guest numbers'),
      key: 'numbers',
      icon: () =>
        h(NIcon, null, {
          default: () => h(ClipboardIcon, { class: 'h-5' }),
        }),
    })
  }
  let mgmtChildren = []
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    permissionsStore.hasPermission(PermissionGuardName.TRUSTED_PARNER_ACCESS) ||
    isImpersonating.value
  ) {
    mgmtChildren.push({
      label: () => h(NuxtLink, { to: '/tours' }, 'Tours'),
      key: 'tours',
      icon: () =>
        h(NIcon, null, { default: () => h(QueueListIcon, { class: 'h-5' }) }),
    })
  }
  if (
    permissionsStore.hasPermission(
      PermissionGuardName.MANAGE_BOOKINGS_ACCESS,
    ) ||
    permissionsStore.hasPermission(PermissionGuardName.TRUSTED_PARNER_ACCESS) ||
    isImpersonating.value
  ) {
    mgmtChildren.push({
      label: () => h(NuxtLink, { to: '/bookings-advanced' }, 'Bookings'),
      key: 'bookings',
      icon: () =>
        h(NIcon, null, { default: () => h(TableCellsIcon, { class: 'h-5' }) }),
    })
  }
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    permissionsStore.hasPermission(PermissionGuardName.TRUSTED_PARNER_ACCESS) ||
    isImpersonating.value
  ) {
    mgmtChildren.push({
      label: () => h(NuxtLink, { to: '/tours/manage' }, 'Tour schedules'),
      key: 'manage',
      icon: () =>
        h(NIcon, null, { default: () => h(PlusIcon, { class: 'h-5' }) }),
    })
  }
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    permissionsStore.hasPermission(PermissionGuardName.TRUSTED_PARNER_ACCESS) ||
    isImpersonating.value
  ) {
    mgmtChildren.push({
      label: () => h(NuxtLink, { to: '/tours/calendar' }, 'Tour calendar'),
      key: 'calendar',
      icon: () =>
        h(NIcon, null, {
          default: () => h(CalendarDaysIcon, { class: 'h-5' }),
        }),
    })
  }
  // if (
  //   permissionsStore.hasPermission(
  //     PermissionGuardName.ORGANISATION_ALL_ACCESS
  //   ) &&
  //   !isImpersonating.value
  // ) {
  //   ops.push({
  //     label: () => h(NuxtLink, { to: '/integrations' }, 'Integrations'),
  //     key: 'integrations',
  //     icon: () =>
  //       h(NIcon, null, {
  //         default: () => h(CodeBracketSquareIcon, { class: 'h-5' }),
  //       }),
  //   })
  // }
  // {
  //   label: () => h(NuxtLink, {to: '/login'}, 'Login'),
  //   key: 'signin',
  //   icon: () => h(NIcon, null, {default: () => h(Bars3Icon, {class: "h-5"})})
  // }
  let settingsChildren = []
  if (permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS)) {
    settingsChildren.push({
      label: () => h(NuxtLink, { to: '/company-team' }, 'Team'),
      key: 'company-team',
      icon: () =>
        h(NIcon, null, {
          default: () => h(UserGroupIcon, { class: 'h-5' }),
        }),
    })
  }
  if (
    permissionsStore.hasPermission(
      PermissionGuardName.ORGANISATION_ALL_ACCESS,
    ) &&
    !isImpersonating.value
  ) {
    settingsChildren.push({
      label: () => h(NuxtLink, { to: '/organisation-settings' }, 'Settings'),
      key: 'organisation-settings',
      icon: () =>
        h(NIcon, null, {
          default: () => h(Cog6ToothIcon, { class: 'h-5' }),
        }),
    })
  }
  if (
    permissionsStore.hasPermission(PermissionGuardName.COMPANY_ALL_ACCESS) ||
    isImpersonating.value
  ) {
    settingsChildren.push({
      label: () => h(NuxtLink, { to: '/company-settings' }, 'Settings'),
      key: 'company-settings',
      icon: () =>
        h(NIcon, null, {
          default: () => h(Cog6ToothIcon, { class: 'h-5' }),
        }),
    })
  }

  if (children.length > 0 && mgmtChildren.length === 0) {
    ops = [...children]
  } else {
    if (children.length > 0) {
      ops.push({
        type: 'group',
        label: 'Operations',
        key: 'operations',
        children,
      })
    }
    if (mgmtChildren.length > 0) {
      ops.push({
        type: 'group',
        label: 'Management',
        key: 'management',
        children: mgmtChildren,
      })
    }
  }

  if (
    permissionsStore.hasPermission(
      PermissionGuardName.ORGANISATION_ALL_ACCESS,
    ) &&
    !isImpersonating.value
  ) {
    // for orgs, add the settings item to the main tree of options, no titles
    ops = [...ops, ...settingsChildren]
  } else if (settingsChildren.length > 0) {
    ops.push({
      type: 'group',
      label: 'Settings',
      key: 'settings',
      children: settingsChildren,
    })
  }

  ops.push({
    key: 'divider-1',
    type: 'divider',
    props: {
      style: {
        // marginTop: '32px',
      },
    },
  })

  ops.push({
    label: () =>
      h(
        'a',
        { onClick: () => signOut() },
        isImpersonating.value ? 'Stop impersonation' : 'Logout',
      ),
    key: 'signout',
    icon: () =>
      h(NIcon, null, { default: () => h(PowerIcon, { class: 'h-5' }) }),
  })
  return ops
})

// Bug with nuxt/supabase https://github.com/nuxt-modules/supabase/issues/28
// means we must wait until the user is actually populated in app before we redirect
// else the middleware will think we arent logged in and just send us back here
watch(user, async (newUser) => {
  if (!newUser?.id) {
    await navigateTo('/')
  }
})
</script>
<style>
.n-menu .n-menu-item-content.n-menu-item-content--selected::before {
  background-color: rgb(243, 243, 245);
}
.n-menu .n-menu-item-content:hover::before {
  background-color: rgb(243, 243, 245) !important;
}
</style>
